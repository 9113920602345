<template>
  <div class="vg_wrapper">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="单据信息" name="first" :key="'first'">
        <childTab1 @isShow="isShow" @showButton="showButtonO" ref="childTab1" />
      </el-tab-pane>
      <el-tab-pane label="审批流转" name="second" :key="'second'" :lazy="true">
        <childTab3 :isShow="isSho" @draftState="draftState" />
      </el-tab-pane>
      <el-tab-pane label="相关文档" name="third" :key="'third'">
        <childTab2 :showButton="showButton" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import MdrlEditMain from '@/views/MessageManagement/MdrlManage/TabChild/MdrlEditMain.vue';
import MdrlEditAbout from '@/views/MessageManagement/MdrlManage/TabChild/MdrlEditAbout.vue';
import MdrlEditAppr from '@/views/MessageManagement/MdrlManage/TabChild/MdrlEditAppr.vue';
export default {
  name: 'MdrlEdit',
  components: {
    childTab1: MdrlEditMain,
    childTab2: MdrlEditAbout,
    childTab3: MdrlEditAppr
  },
  data() {
    return {
      activeName: 'first',
      isSho: true,
      showButton: {}
    };
  },
  created() {},
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    showButtonO(val) {
      this.showButton = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    }
  }
};
</script>

<style scoped lang="scss"></style>
